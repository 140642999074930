import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import { motion } from "framer-motion";
import {
  FaExclamationCircle,
  FaGem,
  FaStar,
  FaTicketAlt,
} from "react-icons/fa";
import WebApp from "@twa-dev/sdk";
import axios from "axios";
import { API_URL } from "../config";
import fetchUserData from "../utils/FetchUserData";
import { useTitle } from "../hooks/useTitle";
import ReactGA from "react-ga4";
import { toast, ToastContainer } from "react-toastify";
import AdSense from "react-adsense";

const Store = () => {
  useTitle("Store - Buy Tickets and Gems");
  const user = WebApp.initDataUnsafe.user || { first_name: "User", id: 0 };
  const effectRan = useRef(false);
  const [userData, setUserData] = useState({
    username: "",
    userId: "",
    referralCode: "",
    inviteLink: "",
    user: null,
  });

  const products = [
    { tickets: 25, stars: 10 },
    { tickets: 60, stars: 20 },
    { tickets: 150, stars: 50 },
    { tickets: 350, stars: 100 },
  ];

  const gems = [
    { gems: 1000, stars: 10 },
    { gems: 2100, stars: 20 },
    { gems: 5200, stars: 50 },
    { gems: 12000, stars: 100 },
  ];

  useEffect(() => {
    if (effectRan.current === false) {
      WebApp.ready();

      if (WebApp.initDataUnsafe) {
        const tgUser = WebApp.initDataUnsafe.user;
        if (tgUser) {
          setUserData((prev) => ({
            ...prev,
            username: tgUser.username || tgUser.first_name,
            userId: tgUser.id,
          }));
          fetchUserData(tgUser).then((data) => {
            if (data) {
              setUserData((prev) => ({ ...prev, user: data }));
            }
          });
        } else {
          console.log("No user data found");
        }
      } else {
        console.log("WebApp.initDataUnsafe is not available");
      }

      effectRan.current = true;
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  const handlePurchase = async (gems, tickets, stars) => {
    ReactGA.event({
      category: "Purchase",
      action: "Purchase initiated",
      label: "Store",
      items: [
        { name: "Tickets", quantity: tickets, stars: stars },
        { name: "Gems", quantity: gems, stars: stars },
      ],
    });

    try {
      const response = await axios.get(
        `${API_URL}/api/payment/createInvoiceLink/${user.id}`,
        {
          params: {
            initData: WebApp.initData,
            gems: gems,
            tickets: tickets,
            stars: stars,
          },
        }
      );
      WebApp.openInvoice(response.data, async (status) => {
        if (status === "paid") {
          toast.success("Payment successful!");
          const paymentResponse = await axios.post(
            `${API_URL}/api/payment/success`,
            {
              userId: user.id,
              username: user.first_name,
              tickets: tickets,
              gems: gems,
              method: "Stars",
              initData: WebApp.initData,
            }
          );
          ReactGA.event({
            category: "Purchase",
            action: "Purchase successful",
            label: "Store",
          });
          setUserData((prev) => ({
            ...prev,
            user: {
              ...prev.user,
              points: prev.user.points + gems,
              tickets: prev.user.tickets + tickets,
            },
          }));
        } else {
          toast.error("Payment cancelled!");
        }
      });
    } catch (error) {
      toast.error("Payment failed! Contact support");
    }
  };

  const ProductCard = ({ icon, title, description, items, handlePurchase }) => {
    const [hoveredButton, setHoveredButton] = useState(null);

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col items-center p-8 bg-gradient-to-b from-[#1a1e2a] to-[#141824] rounded-xl w-full max-w-[640px] text-center shadow-2xl border border-[#2a2f3f]"
      >
        <div className="mb-5 relative">
          <div className="absolute inset-0 bg-yellow-400/20 blur-xl rounded-full" />
          <motion.div
            initial={{ rotateY: 0 }}
            animate={{ rotateY: 360 }}
            transition={{
              duration: 10,
              repeat: Number.POSITIVE_INFINITY,
              ease: "linear",
            }}
          >
            {icon}
          </motion.div>
        </div>

        <h2 className="text-2xl font-bold text-[#4fd1c5] mb-1 tracking-wide drop-shadow-[0_0_8px_rgba(79,209,197,0.3)]">
          {title}
        </h2>

        <p className="text-gray-400 text-sm mb-6 font-medium">{description}</p>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full">
          {items.map((item, index) => (
            <motion.button
              key={index}
              className={`
              relative overflow-hidden bg-gradient-to-br from-[#9366f9] to-[#7c3aed] 
              text-white p-4 rounded-lg flex flex-col items-center justify-center
              shadow-lg border border-purple-500/30
            `}
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              onHoverStart={() => setHoveredButton(index)}
              onHoverEnd={() => setHoveredButton(null)}
              onClick={() =>
                handlePurchase(item.gems || 0, item.tickets || 0, item.stars)
              }
            >
              {hoveredButton === index && (
                <motion.div
                  className="absolute inset-0 bg-white/10 pointer-events-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              )}

              <div className="flex items-center justify-center mb-2">
                <span className="font-bold text-lg">
                  {item.gems ? item.gems : item.tickets}
                </span>
                <div className="ml-1.5">
                  {item.gems ? (
                    <FaGem className="text-yellow-300" />
                  ) : (
                    <FaTicketAlt className="text-white" />
                  )}
                </div>
              </div>

              <motion.div
                animate={
                  hoveredButton === index ? { rotate: [0, 15, -15, 0] } : {}
                }
                transition={{ duration: 0.5 }}
              >
                <div className="flex items-center">
                  <span className="mr-1">{item.stars}</span>
                  <FaStar className="text-yellow-300" />
                </div>
              </motion.div>
            </motion.button>
          ))}
        </div>
      </motion.div>
    );
  };

  return (
    <div className="bg-gradient-to-br from-gray-900 to-black text-white min-h-screen pb-24">
      {userData.user && (
        <Header
          points={userData.user.points}
          name={
            (userData.user.firstName + " " + userData.user.lastName).length > 10
              ? (userData.user.firstName + " " + userData.user.lastName).slice(
                  0,
                  10
                ) + "..."
              : userData.user.firstName + " " + userData.user.lastName
          }
          tickets={userData.user.tickets}
        />
      )}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto py-8 px-4"
      >
        <ToastContainer position="top-right" autoClose={3000} />

        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          className="bg-yellow-600 bg-opacity-20 border border-yellow-500 p-4 rounded-xl shadow-md mb-8"
        >
          <p className="text-sm text-yellow-300 text-center font-semibold">
            <FaExclamationCircle className="inline mr-2" /> All purchases are
            final and non-refundable.
          </p>
        </motion.div>

        <ProductCard
          icon={<FaGem className="text-amber-400" size={70} />}
          title="Buy Gems"
          description="For channel invite links"
          items={gems}
          handlePurchase={handlePurchase}
        />

        <AdSense.Google
          client="ca-pub-4252052358641832"
          slot="6502669487"
          style={{ display: "block" }}
          format="auto"
          responsive="true"
        />

        <ProductCard
          icon={<FaTicketAlt className="text-red-400" size={70} />}
          title="Buy Tickets"
          description="For lucky spins"
          items={products}
          handlePurchase={handlePurchase}
        />

        <AdSense.Google
          client="ca-pub-4252052358641832"
          slot="4385377268"
          style={{ display: "block" }}
          format="auto"
          responsive="true"
          layoutKey="-gw-1+2a-9x+5c"
        />
      </motion.div>
    </div>
  );
};

export default Store;
