import React from "react";
import { FaGem, FaTicketAlt, FaUser } from "react-icons/fa";
import { motion } from "framer-motion";

const Header = ({ name, points, tickets }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="sticky top-0 bg-gradient-to-r from-gray-900 to-gray-800 text-white p-4 flex justify-between items-center z-10 shadow-lg"
    >
      <div className="flex items-center space-x-3">
        <motion.div
          whileHover={{ scale: 1.1, rotate: 360 }}
          transition={{ duration: 0.3 }}
          className="bg-gradient-to-br from-purple-500 to-indigo-600 p-2 rounded-full shadow-md"
        >
          <FaUser className="text-xl" />
        </motion.div>
        <span className="font-semibold text-lg">{name}</span>
      </div>
      <div className="flex items-center space-x-6">
        <motion.div
          className="flex items-center bg-gradient-to-r from-yellow-600 to-yellow-400 px-3 py-1 rounded-full shadow-md"
          whileHover={{ scale: 1.05 }}
        >
          <FaGem className="mr-2 text-yellow-100" />
          <span className="font-bold">{points.toLocaleString()}</span>
        </motion.div>
        <motion.div
          className="flex items-center bg-gradient-to-r from-red-500 to-red-700 px-3 py-1 rounded-full shadow-md"
          whileHover={{ scale: 1.05 }}
        >
          <FaTicketAlt className="mr-2 text-red-100" />
          <span className="font-bold">{tickets.toLocaleString()}</span>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Header;
