import React, { useEffect, useRef, useState } from "react";
import WebApp from "@twa-dev/sdk";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Tasks.css";
import AdSense from "react-adsense";
import Header from "../components/Header";
import { API_URL } from "../config";
import { TonAdInit } from "ton-ai-sdk";
// import TonAIAd from "../components/TonAIAd";
import Loading from "../components/Loading";
import { FaGem, FaLink, FaTicketAlt } from "react-icons/fa";
// import Banner, { Banner3 } from "../components/AdBanner";
import { motion, AnimatePresence } from "framer-motion";
import confetti from "canvas-confetti";
import { useTitle } from "../hooks/useTitle";
import ReactGA from "react-ga4";
import { FormModal } from "../components/FormModal";
import { ShowAdButton } from "../components/MoneTag";
// import { showAd } from "../components/Telega";

const Tasks = () => {
  useTitle("Tasks - Earn Gems and Tickets");
  const [userData, setUserData] = useState({
    username: "",
    userId: "",
    referralCode: "",
    inviteLink: "",
    user: null,
    channels: [],
  });
  const [tasks, setTasks] = useState([]);
  const [userId, setUserId] = useState(null);
  const [taskVerify, setTaskVerify] = useState({
    taskId: "",
    bscode: "",
  });

  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("active");
  const effectRan = useRef(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const BotToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;

  useEffect(() => {
    TonAdInit({
      appId: process.env.REACT_APP_APP_ID,
      debug: false,
    });
  }, []);

  const fetchUserData = async (tgUser) => {
    try {
      const response = await axios.get(`${API_URL}/api/user/${tgUser.id}`, {
        params: { initData: WebApp.initData },
      });
      setUserData((prev) => ({ ...prev, user: response.data }));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Error fetching user data. Please check the console.");
    }
  };

  useEffect(() => {
    if (effectRan.current === false) {
      WebApp.ready();

      if (WebApp.initDataUnsafe) {
        const tgUser = WebApp.initDataUnsafe.user;
        if (tgUser) {
          setUserData((prev) => ({
            ...prev,
            username: tgUser.username || tgUser.first_name,
            userId: tgUser.id,
          }));
          fetchUserData(tgUser);
        } else {
          console.log("No user data found");
        }
      } else {
        console.log("WebApp.initDataUnsafe is not available");
      }

      effectRan.current = true;
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  useEffect(() => {
    const initTelegram = async () => {
      const webAppData = WebApp.initDataUnsafe;
      if (webAppData?.user?.id) {
        setUserId(webAppData.user.id);
        setUserData((prev) => ({
          ...prev,
          username: webAppData.user.username,
        }));
        try {
          const res = await axios.get(`${API_URL}/api/tasks/active`, {
            params: { initData: WebApp.initData },
          });
          const allTasks = res.data;

          const userTasksRes = await axios.get(
            `${API_URL}/api/user/${webAppData.user.id}/tasks`,
            {
              params: { initData: WebApp.initData },
            }
          );
          const userTasks = userTasksRes.data;

          const userTaskMap = {};
          userTasks.tasks.forEach((userTask) => {
            if (userTask.status !== "disable") {
              userTaskMap[userTask.taskId] = userTask.status;
            }
          });

          const sortedTasks = allTasks.sort((a, b) => {
            if (a.isFeatured && !b.isFeatured) return -1;
            if (!a.isFeatured && b.isFeatured) return 1;
            if (a.isTimeLimited && !b.isTimeLimited) return -1;
            if (!a.isTimeLimited && b.isTimeLimited) return 1;
            return new Date(a.expiresAt) - new Date(b.expiresAt);
          });
          const updatedTasks = sortedTasks.map((task) => {
            const status = userTaskMap[task._id];
            const lastSubmission = new Date(userTasks.lastSubmission);
            const isDailyTask =
              task.isDaily &&
              new Date().getTime() - lastSubmission.getTime() <
                24 * 60 * 60 * 1000;
            return {
              ...task,
              status: isDailyTask
                ? "new"
                : status === "completed"
                ? "completed"
                : status === "pending"
                ? "pending"
                : status === "verified"
                ? "verified"
                : status === "unverified"
                ? "unverified"
                : status === "boosted"
                ? "boosted"
                : status === "code"
                ? "code"
                : status === "coded"
                ? "coded"
                : status === "reset"
                ? "reset"
                : status === "banned"
                ? "banned"
                : "new",
              hasCompleted: status === "completed",
            };
          });

          setTasks(updatedTasks);
        } catch (error) {
          console.error("Error fetching tasks:", error);
        }
      } else {
        toast.error("No user data found.");
      }
      setLoading(false);
    };

    initTelegram();
  }, []);

  function extractUsernameFromUrl(url) {
    const regex = /https?:\/\/t\.me\/([a-zA-Z0-9_]+)/;
    const match = url.match(regex);
    return match ? `@${match[1]}` : null;
  }

  const handleVerify = async (task) => {
    // GA event for task verification
    ReactGA.event({
      category: "Task",
      action: "Verify",
      label: task.name,
    });

    const username = extractUsernameFromUrl(task.URL);
    if (!username) {
      toast.error("Invalid URL. Try again.", {
        autoClose: 2000,
        position: "bottom-center",
        pauseOnFocusLoss: false,
      });
      return;
    }
    setButtonLoading(true);
    try {
      const response = await axios.get(
        `https://api.telegram.org/bot${BotToken}/getChatMember`,
        {
          params: { chat_id: username, user_id: userId },
        }
      );

      if (
        response.data.result?.status === "member" ||
        response.data.result?.status === "administrator" ||
        response.data.result?.status === "creator"
      ) {
        await axios.post(`${API_URL}/api/user/${userId}/incrementPoints`, {
          increment: task.points,
          tickets: task.tickets,
          initData: WebApp.initData,
        });

        setUserData((prev) => ({
          ...prev,
          user: {
            ...prev.user,
            points: prev.user.points + task.points,
            tickets: prev.user.tickets + task.tickets,
          },
        }));
        toast.success(`You earned ${task.points} points.`, {
          autoClose: 2000,
          closeButton: false,
          position: "bottom-center",
          pauseOnFocusLoss: false,
        });

        await axios.post(`${API_URL}/api/user/${userId}/tasks`, {
          taskId: task._id,
          status: "completed",
          initData: WebApp.initData,
        });

        setTasks((prevTasks) =>
          prevTasks.map((t) =>
            t._id === task._id ? { ...t, status: "completed" } : t
          )
        );

        // Trigger confetti effect
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        });
      } else {
        toast.error("Join the channel to earn points.", {
          autoClose: 2000,
          position: "bottom-center",
          pauseOnFocusLoss: false,
        });
        setTasks((prevTasks) =>
          prevTasks.map((t) =>
            t._id === task._id ? { ...t, status: "unverified" } : t
          )
        );
      }
      setButtonLoading(false);
    } catch (error) {
      console.error("Verification error:", error);
      toast.error("Error verifying. Try again later.", {
        autoClose: 2000,
        position: "bottom-center",
        pauseOnFocusLoss: false,
      });
      setButtonLoading(false);
    }
  };

  const handleJoin = (task) => {
    // GA event for task join
    ReactGA.event({
      category: "Task",
      action: "Join",
      label: task.name,
    });

    setButtonLoading(true);
    WebApp.openTelegramLink(task.URL, "_blank");
    setTasks((prevTasks) =>
      prevTasks.map((t) => (t._id === task._id ? { ...t, hasJoined: true } : t))
    );
    setButtonLoading(false);
  };

  const handleExternal = async (task, userStatus) => {
    // GA event for external task
    ReactGA.event({
      category: "Task",
      action: "External",
      label: task.name,
    });

    setButtonLoading(true);
    try {
      const url = `${API_URL}/api/user/${userId}/tasks`;

      if (userStatus === "new") {
        await axios.post(url, {
          taskId: task._id,
          status: "verified",
          initData: WebApp.initData,
        });
      } else if (userStatus === "unverified") {
        await axios.put(
          `${API_URL}/api/user/${userId}/tasks/${task._id}/status`,
          {
            status: "verified",
            initData: WebApp.initData,
          }
        );
      }
      setButtonLoading(false);
      toast.success(`Wait for the click to be verified!`, {
        autoClose: 2000,
        position: "bottom-center",
        pauseOnFocusLoss: false,
      });

      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t._id === task._id ? { ...t, status: "verified" } : t
        )
      );
      WebApp.openLink(task.URL, "_blank");
    } catch (error) {
      console.error("Error recording link click:", error);
    }
  };

  const handleShare = async (task, taskStatus, mediaURL, caption) => {
    // GA event for share task
    ReactGA.event({
      category: "Task",
      action: "Share",
      label: "Share to your friends",
    });

    setButtonLoading(true);
    try {
      // Step 1: Save a prepared inline message using the Bot API method savePreparedInlineMessage
      const response = await axios.post(
        `https://api.telegram.org/bot${BotToken}/savePreparedInlineMessage`,
        {
          user_id: userId, // Replace with the target user's ID
          result: {
            type: "photo",
            id: "unique-id", // Replace with a unique ID for the message
            photo_url: mediaURL,
            thumb_url: mediaURL,
            title: "မြန်မာရုံတင်ကားကြီးများ အခမဲ့ ကြည့်နိုင်ပါပြီ။",
            description: "You can watch free movies on Biscope Myanmar App!",
            caption: caption,
            reply_markup: {
              inline_keyboard: [
                [
                  {
                    text: "Watch Free Movies",
                    url: `https://t.me/biscopemyanmar_bot/store?startapp=${userData.referralCode}`,
                  },
                ],
                [
                  {
                    text: "Join App Channel",
                    url: "https://t.me/biscopemyanmarapp",
                  },
                ],
              ],
            },
          },
          allow_user_chats: true,
          allow_bot_chats: false,
          allow_group_chats: true,
          allow_channel_chats: true,
        }
      );
      const msg_id = response.data.result.id;

      // Step 2: Use the msg_id to call the shareMessage method
      WebApp.shareMessage(msg_id, (success) => {
        if (success) {
          const url = `${API_URL}/api/user/${userId}/tasks`;
          if (taskStatus === "reset") {
            axios.put(
              `${API_URL}/api/user/${userId}/tasks/${task._id}/status`,
              {
                status: "verified",
                lastSubmission: new Date(),
                initData: WebApp.initData,
              }
            );
          } else {
            axios.post(url, {
              taskId: task._id,
              status: "verified",
              lastSubmission: new Date(),
              initData: WebApp.initData,
            });
          }
          setTasks((prevTasks) =>
            prevTasks.map((t) =>
              t._id === task._id ? { ...t, status: "verified" } : t
            )
          );
          toast.success("Message shared successfully!", {
            autoClose: 2000,
            position: "bottom-center",
            pauseOnFocusLoss: false,
          });
        } else {
          toast.error("Share to your friends to complete the task.", {
            autoClose: 2000,
            position: "bottom-center",
            pauseOnFocusLoss: false,
          });
        }
        setButtonLoading(false);
      });
    } catch (error) {
      console.error("Error sharing message:", error);
      setButtonLoading(false);
    }
  };

  const handleExternalCode = async (task, userStatus) => {
    // GA event for external code task
    ReactGA.event({
      category: "Task",
      action: "External Code",
      label: task.name,
    });

    setButtonLoading(true);
    try {
      const url = `${API_URL}/api/user/${userId}/tasks`;
      if (userStatus !== "code") {
        await axios.post(url, {
          taskId: task._id,
          status: "code",
          initData: WebApp.initData,
        });
      }
      setButtonLoading(false);
      toast.success(`Enter the code to complete the task.`, {
        autoClose: 2000,
        position: "bottom-center",
        pauseOnFocusLoss: false,
      });

      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t._id === task._id ? { ...t, status: "code" } : t
        )
      );
      WebApp.openLink(task.URL, "_blank");
    } catch (error) {
      console.error("Error recording external code task:", error);
    }
  };

  // const handleExternalCodeVerify = async (task) => {
  //   // GA event for external code verification
  //   ReactGA.event({
  //     category: "Task",
  //     action: "External Code Verify",
  //     label: task.name,
  //   });

  //   setButtonLoading(true);
  //   setModalOpen(true);
  //   setButtonLoading(false);
  // };

  const handleSubmit = (data, task) => {
    if (taskVerify.bscode === data.code) {
      setModalOpen(false);
      toast.success("Code verified successfully!", {
        autoClose: 2000,
        position: "bottom-center",
        pauseOnFocusLoss: false,
      });

      const url = `${API_URL}/api/user/${userId}/tasks/${task.taskId}/status`;

      axios.put(url, {
        status: "coded",
        initData: WebApp.initData,
      });

      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t._id === task.taskId ? { ...t, status: "coded" } : t
        )
      );

      // Trigger confetti effect
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    } else {
      toast.error("Invalid code. Please try again.", {
        autoClose: 2000,
        position: "bottom-center",
        pauseOnFocusLoss: false,
      });
    }
  };

  const handleLinkClick = async (task, userStatus) => {
    // GA event for link click
    ReactGA.event({
      category: "Task",
      action: "Link Click",
      label: task.name,
    });

    setButtonLoading(true);
    try {
      const url = `${API_URL}/api/user/${userId}/tasks`;

      // Choose POST or PUT based on user status
      if (userStatus === "new") {
        // If status is new, perform a POST request
        await axios.post(url, {
          taskId: task._id,
          status: "pending",
          initData: WebApp.initData,
        });
      } else if (userStatus === "unverified") {
        // If status is unverified, perform a PUT request
        await axios.put(
          `${API_URL}/api/user/${userId}/tasks/${task._id}/status`,
          {
            status: "pending",
            initData: WebApp.initData,
          }
        );
      } else {
        toast.error("Invalid user status.");
        setButtonLoading(false);
        return; // Exit the function if the status is not valid
      }

      setButtonLoading(false);
      // Handle the successful response
      toast.success(`Wait for the click to be verified!`, {
        autoClose: 1000,
        position: "bottom-center",
        pauseOnFocusLoss: false,
      });

      // Optionally, redirect to the link after recording
      WebApp.openTelegramLink(task.URL, "_blank");

      // Update local state
      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t._id === task._id ? { ...t, status: "pending", hasJoined: true } : t
        )
      );
    } catch (error) {
      console.error("Error recording link click:", error);
    }
  };

  const handleBoost = async (task, userStatus) => {
    // GA event for boost task
    ReactGA.event({
      category: "Task",
      action: "Boost",
      label: task.name,

      // Optional event fields
      value: task.points,
    });

    setButtonLoading(true);
    WebApp.openTelegramLink(task.URL, "_blank");
    setTasks((prevTasks) =>
      prevTasks.map((t) => (t._id === task._id ? { ...t, hasJoined: true } : t))
    );
    setButtonLoading(false);
  };

  const handleBoostVerify = async (task) => {
    // GA event for boost verification
    ReactGA.event({
      category: "Task",
      action: "Boost Verify",
      label: task.name,
    });

    const username = extractUsernameFromUrl(task.URL);
    setButtonLoading(true);
    try {
      const TelegramAPI = `https://api.telegram.org/bot${BotToken}/getUserChatBoosts`;
      const response = await axios.get(TelegramAPI, {
        params: { chat_id: username, user_id: userId },
      });
      const boosts = response.data.result.boosts;

      if (boosts.length > 0) {
        await axios.post(`${API_URL}/api/user/${userId}/tasks`, {
          taskId: task._id,
          status: "boosted",
          initData: WebApp.initData,
        });
        setTasks((prevTasks) =>
          prevTasks.map((t) =>
            t._id === task._id ? { ...t, status: "boosted" } : t
          )
        );
        setUserData((prev) => ({
          ...prev,
          user: {
            ...prev.user,
            points: prev.user.points + task.points,
            tickets: prev.user.tickets + 1,
          },
        }));
      } else {
        toast.error("You need to boost the channel to earn points.", {
          autoClose: 2000,
          position: "bottom-center",
          pauseOnFocusLoss: false,
        });
      }
      setButtonLoading(false);
    } catch (error) {
      console.error("Error boosting channel:", error);
      setButtonLoading(false);
    }
  };

  const handleClaim = async (task) => {
    // GA event for task claim
    ReactGA.event({
      category: "Task",
      action: "Claim",
      label: task.name,
    });
    setButtonLoading(true);
    // showAd();

    try {
      await axios.put(
        `${API_URL}/api/user/${userId}/tasks/${task._id}/status`,
        {
          status: "completed",
          initData: WebApp.initData,
        }
      );

      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t._id === task._id ? { ...t, status: "completed" } : t
        )
      );

      const pointsIncrement = task.points;

      await axios.post(`${API_URL}/api/user/${userId}/incrementPoints`, {
        increment: pointsIncrement,
        tickets: task.tickets,
        initData: WebApp.initData,
      });

      setUserData((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          points: prev.user.points + pointsIncrement,
          tickets: prev.user.tickets + task.tickets,
        },
      }));
      toast.success(`${pointsIncrement} points claimed.`, {
        autoClose: 2000,
        position: "bottom-center",
        closeOnClick: true,
      });
      setButtonLoading(false);

      // Trigger confetti effect
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    } catch (error) {
      console.error("Error claiming task:", error);
      toast.error("Error claiming task. Please check the console.");
    }
  };

  if (loading) {
    return <Loading />;
  }

  const taskList = (tasks, title, statusClass) => (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="my-6"
    >
      <h2 className="text-xl font-bold mb-3 text-fuchsia-400">{title}</h2>

      <div className="overflow-hidden">
        <AnimatePresence>
          {title === "Daily Tasks" && (
            <>
              <ShowAdButton
                userId={userId}
                webApp={WebApp.initData}
                onPointsUpdate={(newPoints) =>
                  setUserData((prev) => ({
                    ...prev,
                    user: {
                      ...prev.user,
                      points: prev.user.points + newPoints,
                      tickets: prev.user.tickets,
                    },
                  }))
                }
              />
              {/* <TonAIAd
                userId={userId}
                webApp={WebApp.initData}
                onPointsUpdate={(newPoints) =>
                  setUserData((prev) => ({
                    ...prev,
                    user: {
                      ...prev.user,
                      points: prev.user.points + newPoints,
                      tickets: prev.user.tickets + 1,
                    },
                  }))
                }
              /> */}
            </>
          )}
          {tasks.length > 0 ? (
            tasks.map((task) => (
              <motion.div
                key={task._id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={`flex justify-between items-center p-4 border rounded-md my-2   shadow-sm 
                  ${
                    task.isFeatured
                      ? "bg-sky-800 border-white shadow-sky-500"
                      : "bg-slate-600 border-slate-300 shadow-fuchsia-400"
                  }`}
              >
                <div>
                  <span className="text-white font-medium">{task.name}</span>
                  <br />
                  <div className="flex items-center mt-1">
                    {task.points} <FaGem className="mx-1 text-amber-400" />{" "}
                    {task.tickets > 0 && (
                      <>
                        {task.tickets}{" "}
                        <FaTicketAlt className="mx-1 text-red-400" />
                      </>
                    )}
                  </div>
                  {task.desc && (
                    <p className="text-gray-400 text-sm mt-1">{task.desc}</p>
                  )}
                  {task.guide && (
                    <a
                      href={task.guide}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-300 text-sm mt-1 animate-pulse"
                    >
                      <FaLink className="mx-1 text-blue-400 inline" /> Guide -
                      လမ်းညွှန်ဖတ်ပါ
                    </a>
                  )}
                  {task.status === "unverified" && (
                    <span className="text-white text-xs mt-1 shadow-sm bg-red-700 py-0.5 px-3 rounded-md inline-block">
                      Verification failed
                    </span>
                  )}
                  {task.status === "pending" && (
                    <span className="text-white text-xs mt-1 block shadow-sm bg-yellow-500 py-0.5 px-3 rounded-md">
                      Wait 12-24hrs for verification.
                    </span>
                  )}
                </div>
                <div
                  className={` ${
                    task.status === "code"
                      ? "flex flex-col items-center justify-center"
                      : ""
                  }`}
                >
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`text-sm px-3 py-2 rounded-md text-white w-20 text-center block ${
                      task.status !== "completed"
                        ? (!task.hasJoined && task.status === "unverified") ||
                          task.status === "new" ||
                          task.status === "code" ||
                          task.status === "reset"
                          ? "bg-fuchsia-500 hover:bg-fuchsia-600"
                          : task.status === "verified" ||
                            task.status === "boosted" ||
                            task.status === "coded"
                          ? "bg-green-500 hover:bg-green-600"
                          : task.status === "pending"
                          ? "bg-yellow-500 opacity-50 cursor-not-allowed"
                          : task.status === "banned"
                          ? "bg-red-500 opacity-50 cursor-not-allowed"
                          : statusClass
                        : statusClass
                    }`}
                    onClick={() => {
                      if (task.type === "channelJoin" && !buttonLoading) {
                        if (!task.hasJoined) {
                          handleJoin(task);
                        } else {
                          handleVerify(task);
                        }
                      }

                      if (task.type === "share" && !buttonLoading) {
                        if (
                          task.status !== "verified" &&
                          task.status !== "completed"
                        ) {
                          handleShare(
                            task,
                            task.status,
                            task.URL,
                            task.caption
                          );
                        } else if (task.status === "verified") {
                          handleClaim(task);
                        }
                      }

                      // Task Type is Link Click
                      if (task.type === "linkClick" && !buttonLoading) {
                        if (
                          task.status !== "completed" &&
                          task.status !== "pending" &&
                          task.status !== "verified" &&
                          task.status !== "banned"
                        ) {
                          handleLinkClick(task, task.status);
                        } else if (
                          task.status === "verified" &&
                          !buttonLoading
                        ) {
                          handleClaim(task);
                        }
                      }

                      // Task Type is External
                      if (task.type === "external" && !buttonLoading) {
                        if (
                          task.status !== "completed" &&
                          task.status !== "pending" &&
                          task.status !== "verified" &&
                          task.status !== "banned"
                        ) {
                          handleExternal(task, task.status);
                        } else if (
                          task.status === "verified" &&
                          !buttonLoading
                        ) {
                          handleClaim(task);
                        }
                      }

                      // Task Type is External Code
                      if (task.type === "externalCode" && !buttonLoading) {
                        if (
                          task.status !== "completed" &&
                          task.status !== "coded" &&
                          task.status !== "code" &&
                          task.status !== "pending" &&
                          task.status !== "verified" &&
                          !task.hasJoined
                        ) {
                          handleExternalCode(task, task.status);
                        } else if (task.status === "code" && !buttonLoading) {
                          setModalOpen(true);
                          setTaskVerify({
                            taskId: task._id,
                            bscode: task.bscode,
                          });
                        } else if (task.status === "coded" && !buttonLoading) {
                          handleClaim(task);
                        }
                      }

                      // Task Type is Boost
                      if (task.type === "boost" && !buttonLoading) {
                        if (
                          task.status !== "completed" &&
                          task.status !== "pending" &&
                          task.status !== "boosted" &&
                          task.status !== "banned" &&
                          !task.hasJoined &&
                          !buttonLoading
                        ) {
                          handleBoost(task, task.status);
                        } else if (
                          task.hasJoined &&
                          task.status !== "boosted" &&
                          !buttonLoading
                        ) {
                          handleBoostVerify(task);
                        } else if (
                          task.status === "boosted" &&
                          !buttonLoading
                        ) {
                          handleClaim(task);
                        }
                      }
                    }}
                    disabled={
                      task.status === "pending" ||
                      task.status === "completed" ||
                      task.status === "banned" ||
                      task.status === "shared"
                    }
                  >
                    {buttonLoading ? (
                      <svg
                        className="animate-spin h-5 w-5 text-white mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        // fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V4a10 10 0 00-10 10h2zm2 0a6 6 0 016-6V4a8 8 0 00-8 8h2zm2 0a4 4 0 014-4V4a6 6 0 00-6 6h2zm2 0a2 2 0 012-2V4a4 4 0 00-4 4h2z"
                        ></path>
                      </svg>
                    ) : (
                      ""
                    )}
                    {!buttonLoading
                      ? task.type === "channelJoin" &&
                        !task.hasJoined &&
                        task.status !== "completed"
                        ? "Join"
                        : task.type === "channelJoin" &&
                          task.status !== "completed"
                        ? "Verify"
                        : ""
                      : ""}

                    {!buttonLoading
                      ? task.type === "linkClick" && task.status === "verified"
                        ? "Claim"
                        : task.type === "linkClick" &&
                          task.status !== "completed" &&
                          task.status !== "pending" &&
                          task.status !== "verified" &&
                          task.status !== "banned"
                        ? "Click"
                        : ""
                      : ""}
                    {!buttonLoading
                      ? task.type === "external" && task.status === "verified"
                        ? "Claim"
                        : task.type === "external" &&
                          task.status !== "completed" &&
                          task.status !== "pending" &&
                          task.status !== "verified"
                        ? "Click"
                        : ""
                      : ""}
                    {!buttonLoading
                      ? task.type === "boost" &&
                        task.status !== "completed" &&
                        task.status !== "boosted" &&
                        !task.hasJoined
                        ? "Boost"
                        : task.type === "boost" &&
                          task.status !== "completed" &&
                          task.status !== "boosted" &&
                          task.hasJoined
                        ? "Verify"
                        : task.type === "boost" && task.status === "boosted"
                        ? "Claim"
                        : ""
                      : ""}

                    {!buttonLoading
                      ? task.type === "share" &&
                        task.status !== "completed" &&
                        task.status !== "pending" &&
                        task.status !== "verified" &&
                        task.status !== "banned"
                        ? "Share"
                        : task.type === "share" && task.status === "verified"
                        ? "Claim"
                        : ""
                      : ""}

                    {!buttonLoading
                      ? task.type === "externalCode" && task.status === "coded"
                        ? "Claim"
                        : task.type === "externalCode" && task.status === "code"
                        ? "Enter Code"
                        : task.type === "externalCode" &&
                          task.status !== "completed" &&
                          task.status !== "coded"
                        ? "Click"
                        : ""
                      : ""}

                    {!buttonLoading
                      ? task.type === "channelJoin" &&
                        task.status === "verified"
                        ? "Claim"
                        : task.status === "pending"
                        ? "Wait"
                        : task.status === "completed"
                        ? "Done"
                        : task.status === "banned"
                        ? "Ineligible"
                        : ""
                      : ""}
                  </motion.button>
                  {task.type === "externalCode" && task.status === "code" && (
                    <p
                      onClick={() => handleExternalCode(task, task.status)}
                      className="cursor-pointer text-blue-500 hover:text-blue-700 text-sm font-bold underline"
                    >
                      Click
                    </p>
                  )}
                  <span className="text-yellow-400 text-[10px] text-center block mt-2 animate-pulse">
                    {task.isTimeLimited && "[ Ending Soon ]"}
                  </span>
                </div>
              </motion.div>
            ))
          ) : (
            <p className="p-4 text-gray-400 text-center">No tasks available.</p>
          )}
        </AnimatePresence>
      </div>
    </motion.section>
  );

  const dailyTasks = tasks.filter((task) => task.isDaily === true);
  const newTasks = tasks.filter(
    (task) =>
      task.status === "new" ||
      task.status === "unverified" ||
      task.status === "boosted" ||
      task.status === "code" ||
      task.status === "coded"
  );
  const pendingTasks = tasks.filter(
    (task) => task.status === "pending" || task.status === "verified"
  );
  const completedTasks = tasks.filter(
    (task) => task.status === "completed" || task.status === "banned"
  );

  return (
    <div className="bg-slate-800 text-white min-h-screen pb-24">
      <Header
        points={userData.user.points}
        name={
          (userData.user.firstName + " " + userData.user.lastName).length > 10
            ? (userData.user.firstName + " " + userData.user.lastName).slice(
                0,
                10
              ) + "..."
            : userData.user.firstName + " " + userData.user.lastName
        }
        tickets={userData.user.tickets}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto py-20 px-4 flex flex-col"
      >
        <ToastContainer position="bottom-center" autoClose={3000} />
        <FormModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSubmit={(data) => handleSubmit(data, taskVerify)}
        />
        <div className="flex justify-center mb-6">
          <button
            className={`px-6 py-2 rounded-l-full ${
              activeTab === "active" ? "bg-[#9333EA]" : "bg-slate-700"
            }`}
            onClick={() => setActiveTab("active")}
          >
            Active Tasks
          </button>
          <button
            className={`px-6 py-2 rounded-r-full ${
              activeTab === "completed" ? "bg-[#9333EA]" : "bg-slate-700"
            }`}
            onClick={() => setActiveTab("completed")}
          >
            Completed Tasks
          </button>
        </div>
        {activeTab === "active" && (
          <>
            {taskList(dailyTasks, "Daily Tasks", "bg-sky-800")}
            {taskList(newTasks, "New Tasks", "bg-slate-700 hover:bg-slate-600")}
            <AdSense.Google
              client="ca-pub-4252052358641832"
              slot="6502669487"
              style={{ display: "block" }}
              format="auto"
              responsive="true"
              layoutKey="-gw-1+2a-9x+5c"
            />
            {taskList(
              pendingTasks,
              "Pending Tasks",
              "bg-yellow-500 hover:bg-yellow-600"
            )}
            <AdSense.Google
              client="ca-pub-4252052358641832"
              slot="4385377268"
              style={{ display: "block" }}
              format="auto"
              responsive="true"
              layoutKey="-gw-1+2a-9x+5c"
            />
          </>
        )}
        {activeTab === "completed" && (
          <>
            {taskList(completedTasks, "Completed Tasks", "bg-gray-600")}
            <AdSense.Google
              client="ca-pub-4252052358641832"
              slot="4385377268"
              style={{ display: "block" }}
              format="auto"
              responsive="true"
              layoutKey="-gw-1+2a-9x+5c"
            />
          </>
        )}
      </motion.div>
    </div>
  );
};

export default Tasks;