import WebApp from "@twa-dev/sdk";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import { API_URL } from "../config";
import Loading from "../components/Loading";
import { FaChevronRight, FaGem, FaStar } from "react-icons/fa";
import { motion } from "framer-motion";
import { useTitle } from "../hooks/useTitle";
import ReactGA from "react-ga4";
import { toast, ToastContainer } from "react-toastify";
import confetti from "canvas-confetti";
import AdSense from "react-adsense";

export default function Home() {
  useTitle("Home - Biscope Store");
  const [userData, setUserData] = useState({
    username: "",
    userId: "",
    referralCode: "",
    inviteLink: "",
    user: null,
    channels: [],
  });
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const effectRan = useRef(false);

  const getReferralCodeFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("tgWebAppStartParam") || null;
  };

  const fetchUserData = async (tgUser, referrerCode) => {
    try {
      const response = await axios.get(`${API_URL}/api/user/${tgUser.id}`, {
        params: { initData: WebApp.initData },
      });
      setUserData((prev) => ({ ...prev, user: response.data }));
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 404) {
        await axios.post(
          `${API_URL}/api/users`,
          {
            userId: tgUser.id,
            username: tgUser.username,
            firstName: tgUser.first_name,
            lastName: tgUser.last_name,
            isPremium: tgUser.is_premium,
            chatId: tgUser.id,
            referrerCode,
            initData: WebApp.initData,
          },
          { params: { initData: WebApp.initData } }
        );
        const newUserResponse = await axios.get(
          `${API_URL}/api/user/${tgUser.id}`,
          {
            params: { initData: WebApp.initData },
          }
        );
        setUserData((prev) => ({ ...prev, user: newUserResponse.data }));
        setLoading(false);
      } else {
        console.error("Error retrieving user data:", error);
      }
    }
  };

  const fetchChannelsData = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/channels`, {
        params: { initData: WebApp.initData },
      });
      const sortedChannels = response.data.sort((a, b) => {
        const aIsFeatured = a.isFeatured ?? false;
        const bIsFeatured = b.isFeatured ?? false;

        if (aIsFeatured === bIsFeatured) {
          const statusOrder = ["active", "closed", "inactive", "archived"];
          if (a.status === b.status) {
            return new Date(a.closedDate) - new Date(b.closedDate);
          }
          return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
        }
        return bIsFeatured - aIsFeatured;
      });
      setUserData((prev) => ({ ...prev, channels: sortedChannels }));
    } catch (error) {
      console.error("Error fetching channels:", error);
    }
  };

  const filterRecentLinks = (link) => {
    const linkCreatedAt = Date.parse(link.createdAt);
    const twoHoursAgo = Date.now() - 2 * 60 * 60 * 1000;
    return linkCreatedAt >= twoHoursAgo;
  };

  useEffect(() => {
    if (effectRan.current === false) {
      WebApp.ready();
      setUserData((prev) => ({
        ...prev,
        referralCode: getReferralCodeFromURL(),
      }));

      if (WebApp.initDataUnsafe) {
        const tgUser = WebApp.initDataUnsafe.user;
        if (tgUser) {
          setUserData((prev) => ({
            ...prev,
            username: tgUser.username || tgUser.first_name,
            userId: tgUser.id,
          }));
          fetchUserData(tgUser, getReferralCodeFromURL());
          fetchChannelsData();
        } else {
          console.log("No user data found");
        }
      } else {
        console.log("WebApp.initDataUnsafe is not available");
      }

      effectRan.current = true;
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  const GetMoreGems = () => {
    WebApp.showPopup(
      {
        title: "Out of Gems",
        message:
          "You have run out of gems. Finish more tasks or visit the store to get more gems.",
        buttons: [
          { id: "Tasks", type: "default", text: "Complete Tasks" },
          {
            id: "go_to_store",
            type: "default",
            text: "Buy more gems",
          },
        ],
      },
      (buttonId) => {
        if (buttonId === "go_to_store") {
          window.location.href = "/store";
        } else if (buttonId === "Tasks") {
          window.location.href = "/tasks";
        }
      }
    );
  };

  const handlePurchase = async (channelId) => {
    // GA event tracking for purchasing channel
    ReactGA.event({
      category: "Purchase",
      action: "Channel purchased",
      label: "Channel - " + channelId,
    });

    const channel = userData.channels.find((ch) => ch._id === channelId);
    if (!channel) {
      return WebApp.showAlert("Channel not found.");
    }
    try {
      const response = await axios.get(
        `${API_URL}/api/payment/createInvoiceLink/${userData.userId}`,
        {
          params: {
            initData: WebApp.initData,
            gems: 0,
            tickets: 0,
            stars: channel.stars,
          },
        }
      );
      WebApp.openInvoice(response.data, async (status) => {
        if (status === "paid") {
          // Generate Invite Link
          try {
            const response = await axios.get(
              `${API_URL}/api/channel/${channelId}/createPaidInviteLink/${userData.userId}`,
              { params: { initData: WebApp.initData } }
            );
            if (response.data.inviteLink) {
              setUserData((prev) => {
                const updatedLinks = [
                  ...prev.user.links,
                  response.data.inviteLink,
                ];
                return {
                  ...prev,
                  user: {
                    ...prev.user,
                    links: updatedLinks,
                  },
                };
              });
              const updatedUserResponse = await axios.get(
                `${API_URL}/api/user/${userData.userId}`,
                { params: { initData: WebApp.initData } }
              );
              if (updatedUserResponse.data) {
                setUserData((prev) => ({
                  ...prev,
                  user: updatedUserResponse.data,
                }));
              }
            }
            setButtonLoading(false);
            toast.success("Invite link ရပါပြီ။", {
              position: "bottom-center",
              autoClose: 2000,
              closeOnClick: true,
              pauseOnHover: false,
              pauseOnFocusLoss: false,
            });
            confetti({
              particleCount: 100,
              spread: 70,
              origin: { y: 0.6 },
            });
          } catch (error) {
            setButtonLoading(false);
            console.error("Error generating invite link:", error);
          }
          toast.success("Payment successful!", {
            position: "bottom-center",
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
          });
          const paymentResponse = await axios.post(
            `${API_URL}/api/payment/success`,
            {
              userId: userData.userId,
              username: userData.user.firstName,
              tickets: 0,
              gems: 0,
              method: "Stars",
              initData: WebApp.initData,
            }
          );
          ReactGA.event({
            category: "Purchase",
            action: "Purchase successful",
            label: "Store",
          });
          setUserData((prev) => ({
            ...prev,
            user: {
              ...prev.user,
              points: prev.user.points,
              tickets: prev.user.tickets,
            },
          }));
        } else {
          toast.error("Payment cancelled!", {
            position: "bottom-center",
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
          });
        }
      });
    } catch (error) {
      toast.error("Payment failed! Contact support", {
        position: "bottom-center",
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
      });
    }
  };

  const handleGenerateInviteLink = async (channelId) => {
    // GA event tracking for generating invite link
    ReactGA.event({
      category: "Invite",
      action: "Invite link generated",
      label: "Channel - " + channelId,
    });
    setButtonLoading(true);
    const channel = userData.channels.find((ch) => ch._id === channelId);
    if (!channel) {
      setButtonLoading(false);
      return WebApp.showAlert("Channel not found.");
    }
    if (userData.user.points < channel.points) {
      setButtonLoading(false);
      return GetMoreGems();
    }
    if (
      userData.user.links.filter((link) => link.name === channel.name).length >=
      3
    ) {
      setButtonLoading(false);
      return WebApp.showAlert("Invite Link ၃ ခါ ယူထားပြီးပါပြီ။");
    }

    if (
      !window.confirm(
        `Spend ${channel.points} points to generate an invite link?`
      )
    ) {
      setButtonLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `${API_URL}/api/channel/${channelId}/createInviteLink/${userData.userId}`,
        { params: { initData: WebApp.initData } }
      );
      if (response.data.inviteLink) {
        setUserData((prev) => {
          const updatedLinks = [...prev.user.links, response.data.inviteLink];
          return {
            ...prev,
            user: {
              ...prev.user,
              points: prev.user.points - channel.points,
              links: updatedLinks,
            },
          };
        });
        const updatedUserResponse = await axios.get(
          `${API_URL}/api/user/${userData.userId}`,
          { params: { initData: WebApp.initData } }
        );
        if (updatedUserResponse.data) {
          setUserData((prev) => ({ ...prev, user: updatedUserResponse.data }));
        }
      }
      setButtonLoading(false);
      toast.success("Invite link ရပါပြီ။", {
        position: "bottom-center",
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
      });
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    } catch (error) {
      setButtonLoading(false);
      console.error("Error generating invite link:", error);
    }
  };

  if (loading) return <Loading />;

  if (userData.user?.isBanned) {
    return (
      <div className="bg-gradient-to-br from-gray-900 to-black text-white min-h-screen pb-24 p-4">
        <h1 className="text-4xl font-bold text-red-600">Access Denied</h1>
        <p className="text-xl mt-4">You are banned from using this app.</p>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6"
          onClick={() =>
            WebApp.openTelegramLink("https://t.me/biscopesupport_bot")
          }
        >
          Contact Support
        </motion.button>
      </div>
    );
  }
  if (!userData.user) return <div>You are not allowed.</div>;

  const recentLinks = userData.user?.links?.filter(filterRecentLinks) || [];

  return (
    <div className="bg-slate-800 text-white min-h-screen pb-24">
      <Header
        points={userData.user.points}
        name={
          (userData.user.firstName + " " + userData.user.lastName).length > 10
            ? (userData.user.firstName + " " + userData.user.lastName).slice(
                0,
                10
              ) + "..."
            : userData.user.firstName + " " + userData.user.lastName
        }
        tickets={userData.user.tickets}
      />
      <ToastContainer autoClose={1000} />
      <div className="container mx-auto p-4 mt-0 pt-8">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-extrabold text-teal-300 mb-4"
        >
          Welcome, {userData.username || "Movie Buff"}!
        </motion.h1>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex justify-between items-center p-4 bg-gradient-to-r from-purple-800 to-indigo-700 rounded-xl shadow-md"
        >
          <div>
            <span className="text-white font-medium">
              Invite friends & earn rewards!
            </span>
            <div className="text-yellow-400 flex items-center mt-1">
              20 <FaGem className="ml-1" />
            </div>
          </div>
          <motion.a
            href="/referral"
            className="bg-teal-300 hover:bg-teal-400 text-gray-800 font-bold py-2 px-4 rounded-lg transition duration-300"
          >
            Invite Now
          </motion.a>
        </motion.div>
      </div>
      <div className="mt-8 px-4 mx-auto">
        <AdSense.Google
          client="ca-pub-4252052358641832"
          slot="4385377268"
          style={{ display: "block" }}
          format="auto"
          responsive="true"
          layoutKey="-gw-1+2a-9x+5c"
        />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="mt-8 px-4 pb-8"
      >
        <h2 className="text-xl font-semibold text-teal-300 mb-4">
          Your Invite Links
        </h2>
        {recentLinks.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
            {recentLinks.map((link, index) => {
              const linkCreatedAt = new Date(link.createdAt);
              const expirationTime = new Date(
                linkCreatedAt.getTime() + 60 * 60 * 1000
              );
              const timeRemaining = expirationTime - Date.now();
              const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
              const minutes = Math.floor(
                (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
              );
              const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
              return (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.03 }}
                  className="bg-gray-700 p-4 rounded-xl shadow-md"
                >
                  <p className="text-md font-medium">
                    📧 Invite Link:{" "}
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => WebApp.openTelegramLink(link.link)}
                      className="text-teal-300 underline"
                    >
                      {link.name}
                    </motion.button>
                  </p>
                  <p className="text-yellow-300 mt-2 text-sm">
                    {expirationTime > Date.now() ? (
                      <span>
                        ⏰ <span className="font-semibold">Expires in:</span>{" "}
                        {hours}h {minutes}m {seconds}s
                      </span>
                    ) : (
                      "Expired"
                    )}
                  </p>
                </motion.div>
              );
            })}
          </div>
        ) : (
          <p className="text-gray-400 mt-4">
            No recent invite links available.
          </p>
        )}
      </motion.div>
      {/* <div className="mt-8 px-4 mx-auto">
        <AdSense.Google
          client="ca-pub-4252052358641832"
          slot="4385377268"
          style={{ display: "block" }}
          format="auto"
          responsive="true"
          layoutKey="-gw-1+2a-9x+5c"
        />
      </div> */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="mt-8 px-4"
      >
        <h2 className="text-xl font-semibold text-fuchsia-300">
          Available Channels
        </h2>
        {userData.channels.length > 0 ? (
          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4"
            variants={{
              hidden: { opacity: 0 },
              visible: {
                opacity: 1,
                transition: {
                  delayChildren: 0.2,
                  staggerChildren: 0.1,
                },
              },
            }}
            initial="hidden"
            animate="visible"
          >
            {userData.channels.map((channel) => (
              <motion.div
                key={channel.chatId}
                variants={{
                  hidden: { y: 20, opacity: 0 },
                  visible: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      duration: 0.4,
                      ease: "easeInOut",
                    },
                  },
                }}
                whileHover={{ scale: 1.03 }}
                className={`shadow-lg rounded-md p-4 flex flex-col justify-between border border-solid ${
                  channel.isFeatured
                    ? "bg-sky-600 border-sky-300"
                    : "bg-slate-700 border-slate-300"
                }`}
              >
                <div className="flex flex-row align-middle justify-between">
                  <h3 className={`text-lg font-medium mb-2 text-white`}>
                    {channel.name}
                  </h3>
                  <p className="text-gray-600">{channel.description}</p>
                  {channel.status === "active" && (
                    <p className="mt-2 self-center text-yellow-400">
                      <FaGem className="inline text-yellow-400" />{" "}
                      {channel.points}
                    </p>
                  )}
                </div>
                {channel.status === "active" && (
                  <motion.div>
                    <p
                      className="text-yellow-200 text-xs animate-pulse"
                      style={{ animationDuration: "1.5s" }}
                    >
                      Last Date:{" "}
                      {new Date(channel.closedDate).toLocaleDateString("en-GB")}
                    </p>
                  </motion.div>
                )}

                {/* Display Casting Array */}
                {channel.casting && channel.casting.length > 0 && (
                  <div className="mt-4 bg-gray-800 p-2 rounded-md">
                    <p className="text-gray-300 text-sm">
                      {channel.casting.map((cast, index) => (
                        <span key={index}>
                          🎭 {cast.name}
                          {index < channel.casting.length - 1 && ", "}
                        </span>
                      ))}
                    </p>
                  </div>
                )}

                {channel.status === "active" ? (
                  buttonLoading ? (
                    <button
                      className="bg-gray-600 text-white font-bold py-2 px-4 rounded-full mt-4"
                      disabled
                    >
                      Loading...
                    </button>
                  ) : (
                    <>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.2, ease: "easeOut" }}
                        className="bg-[#9333EA] hover:bg-fuchsia-800 text-white font-bold py-2 px-4 rounded-md mt-4 transition duration-300 ease-in-out"
                        onClick={() => handleGenerateInviteLink(channel._id)}
                      >
                        Get Invite Link 🚀
                      </motion.button>
                      <motion.div className="mt-2 flex flex-row justify-center gap-2">
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          transition={{ duration: 0.2, ease: "easeOut" }}
                          className="bg-yellow-500 text-white flex-1 font-bold py-2 px-4 rounded-md mt-4 transition duration-300 ease-in-out"
                          onClick={() => WebApp.openTelegramLink(channel.url)}
                        >
                          Buy - {channel.points} Kyats
                        </motion.button>
                        {channel.stars > 0 && (
                          <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ duration: 0.2, ease: "easeOut" }}
                            className="bg-yellow-500 hover:bg-fuchsia-800 text-white font-bold py-2 px-4 rounded-md mt-4 transition duration-300 ease-in-out"
                            onClick={() => handlePurchase(channel._id)}
                          >
                            <span className="flex justify-center items-center">
                              <span>Buy - </span> {channel.stars}{" "}
                              <FaStar className="text-yellow-300" />
                            </span>
                          </motion.button>
                        )}
                      </motion.div>
                    </>
                  )
                ) : channel.status === "closed" || channel.status === "paid" ? (
                  <>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      transition={{ duration: 0.2, ease: "easeOut" }}
                      className="bg-yellow-500 text-white font-bold py-2 px-4 rounded-md mt-4 transition duration-300 ease-in-out"
                      onClick={() => WebApp.openTelegramLink(channel.url)}
                    >
                      Buy Now - {channel.points} Kyats
                    </motion.button>
                    {channel.stars > 0 && (
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.2, ease: "easeOut" }}
                        className="bg-yellow-500 hover:bg-fuchsia-800 text-white font-bold py-2 px-4 rounded-md mt-4 transition duration-300 ease-in-out"
                        onClick={() => handlePurchase(channel._id)}
                      >
                        <span className="flex justify-center items-center">
                          <span>Buy Now - </span> {channel.stars}{" "}
                          <FaStar className="text-yellow-300" />
                        </span>
                      </motion.button>
                    )}
                  </>
                ) : (
                  <motion.button
                    className="bg-gray-600 text-white font-bold py-2 px-4 rounded-full mt-4"
                    disabled
                  >
                    Coming Soon
                  </motion.button>
                )}
              </motion.div>
            ))}
          </motion.div>
        ) : (
          <p className="text-gray-400 mt-4">No channels available.</p>
        )}
      </motion.div>
    </div>
  );
}