import React, { useEffect, useRef, useState } from "react";
import { Wheel } from "react-custom-roulette";
import "./Game.css";
import axios from "axios";
import WebApp from "@twa-dev/sdk";
import { API_URL } from "../config";
import Header from "../components/Header";
import fetchUserData from "../utils/FetchUserData";
import { FaTicketAlt, FaGem } from "react-icons/fa";
import { useTitle } from "../hooks/useTitle";
import ReactGA from "react-ga4";
import confetti from "canvas-confetti";
import { motion, AnimatePresence } from "framer-motion";
// import { showAd } from "../components/Telega";

const data = [
  { option: "30", probability: 0.22 },
  { option: "100", probability: 0.02 },
  { option: "30", probability: 0.22 },
  { option: "30", probability: 0.25 },
  { option: "50", probability: 0.08 },
  { option: "10", probability: 0.15 },
  { option: "80", probability: 0.055 },
  { option: "500", probability: 0.005 },
];

function getRandomPrizeIndex() {
  const random = Math.random();
  let cumulativeProbability = 0;

  for (let i = 0; i < data.length; i++) {
    cumulativeProbability += data[i].probability;
    if (random < cumulativeProbability) {
      return i;
    }
  }
  return 0;
}

function Game() {
  useTitle("Lucky Gems Spin Game");
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [winningMsg, setWinningMsg] = useState("");
  const [spinCount, setSpinCount] = useState(0);
  const effectRan = useRef(false);
  const [userData, setUserData] = useState({
    username: "",
    userId: "",
    referralCode: "",
    inviteLink: "",
    user: null,
  });

  const GetMoreTicket = () => {
    WebApp.showPopup(
      {
        title: "Out of Tickets",
        message:
          "You have run out of tickets. Please visit the store to get more tickets.",
        buttons: [
          { id: "Tasks", type: "default", text: "Complete Tasks" },
          {
            id: "go_to_store",
            type: "default",
            text: "Get more tickets",
          },
        ],
      },
      (buttonId) => {
        if (buttonId === "go_to_store") {
          window.location.href = "/store";
        } else if (buttonId === "Tasks") {
          window.location.href = "/tasks";
        }
      }
    );
  };

  function onStopSpinning(userId) {
    axios.post(`${API_URL}/api/user/${userId}/updateSpinCount`, {
      points: parseInt(data[prizeNumber].option),
      initData: WebApp.initData,
    });
    setMustSpin(false);
    setWinningMsg(`You won ${data[prizeNumber].option} Gems!`);
    setSpinCount(spinCount - 1);
    // showAd();
    setUserData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        points: prev.user.points + parseInt(data[prizeNumber].option),
        tickets: prev.user.tickets - 1,
      },
    }));
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  }

  useEffect(() => {
    if (effectRan.current === false) {
      WebApp.ready();

      if (WebApp.initDataUnsafe) {
        const tgUser = WebApp.initDataUnsafe.user;
        if (tgUser) {
          setUserData((prev) => ({
            ...prev,
            username: tgUser.username || tgUser.first_name,
            userId: tgUser.id,
          }));
          fetchUserData(tgUser).then((data) => {
            if (data) {
              setUserData((prev) => ({ ...prev, user: data }));
              setSpinCount(data.tickets);
            }
          });
        } else {
          console.log("No user data found");
        }
      } else {
        console.log("WebApp.initDataUnsafe is not available");
      }

      effectRan.current = true;
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  const handleSpinClick = () => {
    ReactGA.event({
      category: "Spin",
      action: "Make a spin",
      label: "Game",
    });

    setWinningMsg("");
    const newPrizeNumber = getRandomPrizeIndex();
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  return (
    <div className="bg-gradient-to-br from-gray-900 to-black text-white min-h-screen pb-24">
      {userData.user && (
        <Header
          points={userData.user.points}
          name={
            (userData.user.firstName + " " + userData.user.lastName).length > 10
              ? (userData.user.firstName + " " + userData.user.lastName).slice(
                  0,
                  10
                ) + "..."
              : userData.user.firstName + " " + userData.user.lastName
          }
          tickets={userData.user.tickets}
        />
      )}
      <div className="container mx-auto px-4 py-8">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-extrabold text-center text-teal-300 mb-8"
        >
          Lucky Gems Spin
        </motion.h1>
        <div className="flex flex-col items-center">
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="wheel-container mb-8"
          >
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              backgroundColors={[
                "#4B0082",
                "#8A2BE2",
                "#9932CC",
                "#9400D3",
                "#8B008B",
                "#800080",
                "#4B0082",
                "#6A5ACD",
              ]}
              textColors={["#ffffff"]}
              outerBorderColor="#FFD700"
              outerBorderWidth={5}
              innerRadius={20}
              innerBorderColor="#FFD700"
              innerBorderWidth={5}
              radiusLineColor="#FFD700"
              radiusLineWidth={2}
              fontSize={20}
              perpendicularText={true}
              textDistance={60}
              onStopSpinning={() => onStopSpinning(userData.userId)}
            />
          </motion.div>
          <AnimatePresence>
            {winningMsg && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="text-2xl font-bold text-yellow-400 mb-6"
              >
                {winningMsg}
              </motion.div>
            )}
          </AnimatePresence>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-bold py-3 px-6 rounded-full text-lg shadow-lg transition duration-300 ${
              mustSpin || spinCount === 0 ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={spinCount !== 0 ? handleSpinClick : GetMoreTicket}
            disabled={mustSpin || spinCount === 0}
          >
            {spinCount !== 0 ? (
              <>
                Spin [ <FaTicketAlt className="inline text-yellow-400 mr-1" />{" "}
                {spinCount} ]
              </>
            ) : (
              "Get More Tickets"
            )}
          </motion.button>
          {spinCount === 0 && (
            <motion.a
              whileHover={{ scale: 1.05 }}
              href="/store"
              className="text-teal-300 mt-4 underline"
            >
              Visit the store
            </motion.a>
          )}
        </div>
      </div>
    </div>
  );
}

export default Game;
