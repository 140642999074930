/* global show_8843260 */
import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../config";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";

export const ShowAdButton = (props) => {
  const [cooldown, setCooldown] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [adCount, setAdCount] = useState(0);

  const completedAd = () => {
    ReactGA.event({
      category: "Ad",
      action: "Ad watched",
      label: "Ad watched",
    });
    axios
      .post(`${API_URL}/api/user/${props.userId}/incrementPoints`, {
        increment: 20,
        tickets: 0,
        initData: props.webApp,
      })
      .then((res) => {
        props.onPointsUpdate(20);
        const expiry = Date.now() + 300000; // 5 minutes in milliseconds
        localStorage.setItem("adCooldown", JSON.stringify({ expiry }));
        toast.success("You have earned 20 points!", {
          autoClose: 2000,
          position: "bottom-center",
          closeButton: false,
          pauseOnFocusLoss: false,
        });
        axios
          .post(`${API_URL}/api/user/${props.userId}/updateAdCooldown`, {
            adCooldown: expiry,
            initData: props.webApp,
          })
          .then((res) => {
            console.log("Ad cooldown saved to MongoDB");
          })
          .catch((err) => {
            console.error("Error saving ad cooldown to MongoDB:", err);
          });
        setCooldown(true);
        setTimeLeft(300); // 5 minutes in seconds

        const newAdCount = adCount + 1;
        setAdCount(newAdCount);
        localStorage.setItem("adCount", newAdCount);
        if (newAdCount === 1) {
          const adExpiry = Date.now() + 86400000; // 24 hours in milliseconds
          localStorage.setItem("adExpiry", JSON.stringify(adExpiry));
          axios
            .post(`${API_URL}/api/user/${props.userId}/updateAdCountExpiry`, {
              adExpiry: adExpiry,
              adCount: newAdCount,
              initData: props.webApp,
            })
            .then((res) => {
              console.log("Ad count expiry saved to MongoDB");
            })
            .catch((err) => {
              console.error("Error saving ad count expiry to MongoDB:", err);
            });
        }

        if (newAdCount > 1) {
          axios
            .post(`${API_URL}/api/user/${props.userId}/updateAdCount`, {
              adCount: newAdCount,
              initData: props.webApp,
            })
            .then((res) => {
              console.log("Ad count updated in MongoDB");
            })
            .catch((err) => {
              console.error("Error updating ad count in MongoDB:", err);
            });
        }

        if (newAdCount >= 5) {
          setCooldown(false);
        }
      });
  };

  useEffect(() => {
    // Effect function
    if (window.show_8843260) {
      return;
    }
  }, []);

  useEffect(() => {
    // Effect function
    // if (window.show_8843260) {
    //   return;
    // }

    // const tag = document.createElement("script");

    // tag.src = "//doodiwom.com/vignette.min.js";
    // tag.dataset.zone = "8843260";
    // tag.dataset.sdk = "show_8843260";

    // document.body.appendChild(tag);

    axios
      .get(`${API_URL}/api/user/${props.userId}`, {
        params: { initData: props.webApp },
      })
      .then((res) => {
        const { adCooldown, adCount, adExpiry } = res.data;
        if (adCooldown) {
          const remainingTime = new Date(adCooldown).getTime() - Date.now();
          if (remainingTime > 0) {
            setCooldown(true);
            setTimeLeft(Math.floor(remainingTime / 1000));
            localStorage.setItem(
              "adCooldown",
              JSON.stringify({ expiry: adCooldown })
            );
          } else {
            localStorage.removeItem("adCooldown");
          }
        }

        if (adCount && adExpiry) {
          if (Date.now() < new Date(adExpiry).getTime()) {
            setAdCount(adCount);
            localStorage.setItem("adCount", adCount);
            localStorage.setItem("adExpiry", JSON.stringify(adExpiry));
          } else {
            localStorage.removeItem("adCount");
            localStorage.removeItem("adExpiry");
          }
        }
      })
      .catch((err) => {
        console.error("Error retrieving ad data from MongoDB:", err);
      });

    const savedCooldown = localStorage.getItem("adCooldown");
    const savedAdCount = localStorage.getItem("adCount");
    const savedAdExpiry = localStorage.getItem("adExpiry");

    if (savedCooldown) {
      const cooldownData = JSON.parse(savedCooldown);
      const remainingTime = cooldownData.expiry - Date.now();
      if (remainingTime > 0) {
        setCooldown(true);
        setTimeLeft(Math.floor(remainingTime / 1000));
      } else {
        localStorage.removeItem("adCooldown");
      }
    }

    if (savedAdCount && savedAdExpiry) {
      const adExpiry = JSON.parse(savedAdExpiry);
      if (Date.now() < adExpiry) {
        setAdCount(parseInt(savedAdCount, 10));
      } else {
        localStorage.removeItem("adCount");
        localStorage.removeItem("adExpiry");
      }
    }
  }, []);

  useEffect(() => {
    let timer;
    if (cooldown) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setCooldown(false);
            localStorage.removeItem("adCooldown");
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [cooldown]);
  const showAd = () => {
    ReactGA.event({
      category: "Ad",
      action: "Ad button clicked",
      label: "Ad button clicked",
    });
    if (adCount >= 5) {
      toast.error("ကြော်ငြာ ၅ ခု ပြည့်ပါပြီ။", {
        autoClose: 2000,
        position: "bottom-center",
        closeButton: false,
        pauseOnFocusLoss: false,
      });
      return;
    }

    if (cooldown) {
      toast.error("အချိန်ပြည့်အောင်စောင့်ပါ။", {
        autoClose: 2000,
        position: "bottom-center",
        closeButton: false,
        pauseOnFocusLoss: false,
      });
      return;
    }
    show_8843260().then(() => {
      completedAd();
    });
  };

  return (
    <div className=" bg-[#9333EA] shadow-sm shadow-fuchsia-300 flex justify-between items-center p-4 border rounded-md border-slate-300 last:border-b-0 my-2">
      <div className="flex flex-col items-start">
        <span className="text-white font-medium">Watch Ads</span>
        <div className="text-yellow-300">Earn 20 points by watching ads</div>
        <div className="text-sm text-gray-200">
          {adCount}/5 ads watched today
        </div>

        {adCount >= 5 && (
          <div className="text-sm text-orange-600">
            {`Next ad available in ${String(
              Math.floor(
                (new Date(
                  JSON.parse(localStorage.getItem("adExpiry"))
                ).getTime() -
                  Date.now()) /
                  3600000
              )
            ).padStart(2, "0")}h : ${String(
              Math.floor(
                ((new Date(
                  JSON.parse(localStorage.getItem("adExpiry"))
                ).getTime() -
                  Date.now()) %
                  3600000) /
                  60000
              )
            ).padStart(2, "0")}m`}
          </div>
        )}
      </div>
      <div>
        <button
          className="bg-fuchsia-600 transition duration-300 ease-in-out transform hover:scale-110 shadow-white shadow-inner px-2 py-1 rounded text-white w-20"
          onClick={() => {
            showAd();
          }}
        >
          {cooldown && adCount < 5
            ? `${String(Math.floor(timeLeft / 60)).padStart(2, "0")}:${String(
                timeLeft % 60
              ).padStart(2, "0")}`
            : adCount >= 5
            ? "Done"
            : "Watch"}
        </button>
      </div>
    </div>
  );
};
