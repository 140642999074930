import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import StoreFrontIcon from "@mui/icons-material/Storefront";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CasinoIcon from "@mui/icons-material/Casino";
import Tasks from "./pages/Tasks";
import Referral from "./pages/Referral";
import Store from "./pages/Store";
import WebApp from "@twa-dev/sdk";
import axios from "axios";
import { API_URL } from "./config";
import BannedMessage from "./components/BannedMessage";
import Game from "./pages/Game";
import Loading from "./components/Loading";
import ReactGA from "react-ga4";
import { motion, AnimatePresence } from "framer-motion";

function App() {
  const [isBanned, setIsBanned] = useState(false);
  const [isTelegramMiniApp, setIsTelegramMiniApp] = useState(false);
  const [isPopUp, setIsPopUp] = useState(false);
  const [isLoading, setLoading] = useState(true);

  ReactGA.initialize("G-T4PQR5J1XE");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [window.location.pathname]);

  useEffect(() => {
    const requestPermissions = async () => {
      try {
        await WebApp.requestPermissions({
          permissions: ["write", "read"],
        });
      } catch (error) {
        console.error("Permission request failed", error);
      }
    };

    requestPermissions();
  }, []);

  // Check if the user is banned
  const checkBanned = async () => {
    const tgUser = WebApp.initDataUnsafe;
    if (tgUser?.user) {
      const res = await axios.get(`${API_URL}/api/user/${tgUser.user.id}`, {
        params: { initData: WebApp.initData },
      });
      return res.data.isBanned;
    }
    return false;
  };

  const checkPopUp = async () => {
    const tgUser = WebApp.initDataUnsafe;
    if (tgUser?.user) {
      const res = await axios.get(`${API_URL}/api/user/${tgUser.user.id}`, {
        params: { initData: WebApp.initData },
      });
      return res.data.isPopUp;
    }
    return false;
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.onclckmn.com/static/onclicka.js";
    script.dataset.admpid = "270297"; //  AD-CODE-ID
    script.async = true;
    document.head.appendChild(script);
  }, []);

  const checkTelegramMiniApp = () => {
    return WebApp.initDataUnsafe?.user ? true : false;
  };

  const fetchData = async () => {
    const banned = await checkBanned();
    setIsBanned(banned);
    const popup = await checkPopUp();
    setIsPopUp(popup);
  };

  useEffect(() => {
    WebApp.ready();
    WebApp.disableVerticalSwipes();
    WebApp.enableClosingConfirmation();
    WebApp.expand();

    if (checkTelegramMiniApp()) {
      setIsTelegramMiniApp(true);
    }
    // Fetch banned status
    setLoading(false);
    fetchData();
  }, []);

  if (isLoading) {
    <Loading />;
  }

  if (isBanned) {
    return <BannedMessage />;
  }

  if (isPopUp) {
    WebApp.showPopup(
      {
        title: "Gems are adjusted",
        message:
          "You violated our rules and your gems have been adjusted. This decision is final and we will not discuss anymore. Sorry for the inconvenience caused.",
        buttons: [{ id: "Ok", type: "default", text: "OK" }],
      },
      (buttonId) => {
        if (buttonId === "Ok") {
          window.location.href = "/";
        }
      }
    );
    const tgUser = WebApp.initDataUnsafe;
    axios.put(
      `${API_URL}/api/user/${tgUser.user.id}`,
      {
        isPopUp: false,
      },
      {
        params: { initData: WebApp.initData },
      }
    );
  }

  if (!isTelegramMiniApp && !isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-800 text-white">
        <h1 className="text-2xl">
          Please open this page in the Telegram app to continue
        </h1>
      </div>
    );
  }

  return (
    <Router>
      <div className="App bg-gray-800 min-h-screen flex flex-col">
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/store" element={<Store />} />
            <Route path="/game" element={<Game />} />
          </Routes>
        </main>

        <Footer />
      </div>
    </Router>
  );
}

const Footer = () => {
  const location = useLocation();
  const [navWidth, setNavWidth] = useState(0);
  const navRef = useRef(null);

  const tabs = [
    { path: "/", icon: HomeIcon, label: "Home" },
    { path: "/tasks", icon: AssignmentTurnedInIcon, label: "Tasks" },
    { path: "/store", icon: StoreFrontIcon, label: "Store" },
    { path: "/game", icon: CasinoIcon, label: "Game" },
    { path: "/referral", icon: GroupIcon, label: "Referral" },
  ];

  useEffect(() => {
    if (navRef.current) {
      setNavWidth(navRef.current.offsetWidth);
    }
  }, []);

  const getTabPosition = (index) => {
    const tabWidth = navWidth / tabs.length;
    return `${index * tabWidth}px`;
  };

  const activeIndex = tabs.findIndex((tab) => tab.path === location.pathname);

  return (
    <div className="fixed bottom-4 left-0 right-0 flex justify-center z-50">
      <motion.nav
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-11/12 max-w-[450px]"
      >
        <div
          ref={navRef}
          className="relative bg-gradient-to-r from-gray-900 to-slate-800 text-white shadow-lg rounded-2xl overflow-hidden h-16"
        >
          <div className="flex justify-around items-end h-full">
            {tabs.map((tab, index) => (
              <Link
                key={tab.path}
                to={tab.path}
                className={`relative z-20 flex flex-col items-center justify-center w-full h-full transition-all duration-300 ease-in-out ${
                  location.pathname === tab.path
                    ? "text-fuchsia-400"
                    : "text-gray-400 hover:text-white"
                }`}
              >
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className={`p-2 rounded-full ${
                    location.pathname === tab.path ? "bg-fuchsia-900/30" : ""
                  }`}
                >
                  <tab.icon
                    className={`text-2xl transition-all duration-300 ease-in-out`}
                  />
                </motion.div>
                <AnimatePresence>
                  {location.pathname === tab.path && (
                    <motion.span
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{ duration: 0.2 }}
                      className="text-xs mt-0.5 font-medium"
                    >
                      {tab.label}
                    </motion.span>
                  )}
                </AnimatePresence>
              </Link>
            ))}
          </div>
          {/* Animated background for active tab */}
          <motion.div
            className="absolute z-10 w-1/5 h-[120%] bg-gradient-to-t from-fuchsia-600 to-fuchsia-800 rounded-full"
            initial={false}
            animate={{
              left: getTabPosition(activeIndex),
            }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          />
        </div>
      </motion.nav>
    </div>
  );
};

export default App;
