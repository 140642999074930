import axios from "axios";
import { toast } from "react-toastify"; // Replace with your actual API URL
import WebApp from "@twa-dev/sdk";
import { API_URL } from "../config";

const fetchUserData = async (tgUser) => {
  try {
    const response = await axios.get(`${API_URL}/api/user/${tgUser.id}`, {
      params: { initData: WebApp.initData },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    toast.error("Error fetching user data. Please check the console.");
    return null;
  }
};

export default fetchUserData;
