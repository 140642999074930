import React, { useState } from "react";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  return (
    <div
      className="fixed max-w-full inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &#x2715; {/* Close button */}
        </button>
        {children}
      </div>
    </div>
  );
};

export const FormModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({ code: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData({ code: "" });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-lg font-bold">Submit Your Details</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm text-black">Enter Code</label>
          <input
            type="text"
            className="w-full px-3 py-2 border rounded-lg text-black"
            name="code"
            value={formData.code}
            onChange={handleChange}
            autoFocus
            required
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-green-500 text-black rounded-lg">
          Submit
        </button>
      </form>
    </Modal>
  );
};
