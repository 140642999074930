import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import WebApp from "@twa-dev/sdk";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../config";
import { FaGem, FaUserCircle, FaCopy, FaShareAlt } from "react-icons/fa";
import { ImSpinner2 } from "react-icons/im";
import Header from "../components/Header";
import { motion, AnimatePresence } from "framer-motion";
import { useTitle } from "../hooks/useTitle";
import ReactGA from "react-ga4";
import AdSense from "react-adsense";

const ReferralPage = () => {
  useTitle("Referral Program");
  const [referralURL, setReferralURL] = useState("");
  const [referredUsers, setReferredUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const effectRan = useRef(false); // useRef to track useEffect execution

  useEffect(() => {
    if (effectRan.current === false) {
      WebApp.ready();
      const webAppData = WebApp.initDataUnsafe;
      if (webAppData?.user) {
        fetchUserData(webAppData);
        fetchReferredUsers(webAppData);
      } else {
        console.log("User data not available.");
        setLoading(false);
      }
      effectRan.current = true;
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  const fetchUserData = async (webAppData) => {
    try {
      const res = await axios.get(`${API_URL}/api/user/${webAppData.user.id}`, {
        params: { initData: WebApp.initData },
      });
      const userData = res.data;
      setUser(userData);
      setReferralURL(
        `https://t.me/biscopemyanmar_bot/store?startapp=${userData.referralCode}`
      );
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to load user data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchReferredUsers = async (webAppData) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/user/${webAppData.user.id}/referred`,
        {
          params: { initData: WebApp.initData },
        }
      );
      setReferredUsers(response.data);
    } catch (error) {
      console.error("Error fetching referred users:", error);
      toast.error("Failed to load referred users. Please try again.");
    }
  };

  const copyToClipboard = () => {
    ReactGA.event({
      category: "Referral",
      action: "Referral URL copied",
      label: "Referral Page",
    });

    navigator.clipboard.writeText(referralURL).then(() => {
      setCopied(true);
      toast.success("Referral URL copied!", {
        position: "bottom-center",
        autoClose: 2000,
        closeOnClick: true,
      });
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="bg-gradient-to-br from-gray-900 to-black text-white min-h-screen pb-24">
      {user && (
        <Header
          points={user.points}
          name={
            (user.firstName + " " + user.lastName).length > 10
              ? (user.firstName + " " + user.lastName).slice(0, 10) + "..."
              : user.firstName + " " + user.lastName
          }
          tickets={user.tickets}
        />
      )}
      <div className="container mx-auto p-6 max-w-lg space-y-6 pt-20">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-extrabold mb-6 text-teal-300 text-center"
        >
          Invite Friends & Earn Rewards!
        </motion.h2>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-center text-lg text-gray-300"
        >
          Invite a friend and earn <FaGem className="inline text-amber-400" />{" "}
          <span className="text-amber-400 font-bold">20</span>!
        </motion.p>

        <div className="space-y-4 text-center">
          <p className="text-lg text-gray-300">Your referral URL:</p>
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="flex items-center justify-center border p-4 rounded-xl bg-gray-800 shadow-md relative"
          >
            {loading ? (
              <ImSpinner2 className="text-teal-300 animate-spin text-2xl" />
            ) : (
              <p className="break-all text-teal-300">{referralURL}</p>
            )}
          </motion.div>

          <div className="flex flex-row gap-4 justify-center mt-4">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-semibold py-2 px-4 rounded-xl transition duration-300 shadow-md flex items-center"
              onClick={() =>
                WebApp.openTelegramLink(
                  `https://t.me/share/url?url=${encodeURIComponent(
                    referralURL
                  )}`
                )
              }
            >
              <FaShareAlt className="mr-2" /> Invite Friend
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={copyToClipboard}
              className="bg-gray-700 hover:bg-gray-800 text-teal-300 font-semibold py-2 px-4 rounded-xl transition duration-300 shadow-md flex items-center"
            >
              <FaCopy className="mr-2" /> {copied ? "Copied!" : "Copy URL"}
            </motion.button>
          </div>
        </div>

        <AdSense.Google
          client="ca-pub-4252052358641832"
          slot="4385377268"
          style={{ display: "block" }}
          format="auto"
          responsive="true"
          layoutKey="-gw-1+2a-9x+5c"
        />

        <motion.h3
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="text-xl font-semibold text-teal-300 text-center mt-6"
        >
          Referred Friends
        </motion.h3>

        <AnimatePresence>
          {referredUsers.length > 0 ? (
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              className="space-y-3 mt-4"
            >
              {referredUsers.map((user, index) => (
                <motion.li
                  key={user.userId}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className="flex items-center space-x-3 bg-gradient-to-br from-gray-800 to-gray-700 p-3 rounded-xl shadow-md"
                >
                  <FaUserCircle className="text-gray-500 text-3xl" />
                  <div>
                    <p className="font-medium text-gray-200">
                      {user.firstName} {user.lastName}
                    </p>
                    <p className="text-gray-500 text-sm">@{user.username}</p>
                  </div>
                </motion.li>
              ))}
            </motion.ul>
          ) : (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              className="text-gray-500 text-center mt-4"
            >
              No friends referred yet.
            </motion.p>
          )}
        </AnimatePresence>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.8 }}
          className="text-center text-gray-300 mt-6"
        >
          Earn reward points for each friend who signs up using your referral
          link!
        </motion.p>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ReferralPage;
