import React from "react";

function BannedMessage(props) {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-red-600 to-yellow-600 animate-pulse">
      <div className="text-center p-6 bg-white rounded-lg shadow-xl w-11/12 max-w-sm transform hover:scale-105 transition-all duration-300 ease-in-out border-4 border-dashed border-red-700">
        <h1 className="text-2xl font-extrabold text-red-700 uppercase tracking-widest">
          🚫 Locked Out 🚫
        </h1>
        <p className="text-lg mt-4 text-gray-800 font-bold">Gone Forever!</p>
        <p className="text-sm mt-2 text-gray-500 italic">
          Banned for life. No more fun allowed. 🎭🚷
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="mx-auto mt-6 animate-bounce text-red-700"
          width="80"
          height="50"
        >
          <rect x="9" y="11" width="6" height="8" rx="1"></rect>
          <path d="M10 11V7a2 2 0 1 1 4 0v4"></path>
        </svg>
      </div>
    </div>
  );
}

export default BannedMessage;
